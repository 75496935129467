import API from 'dt-cvm-api';
import React, {
  useState, useContext, useEffect, useRef
} from 'react';
import env from '@beam-australia/react-env';
import moment from 'moment';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { CVMContext } from '../../CVM/CVM';
import { DetailSection, DetailVBar } from '../DetailSection';
import SelectVTVAirCheck from './SelectVTVAirCheck';
import CustomerInformation from './CustomerInformation';
import TaxSection from './TaxSection';
import { theme } from '../../../helpers/GlobalStyles';
import Vehicle from '../Vehicle/Vehicle';
import DeactivatedVehicles from '../Vehicle/DeactivatedVehicles';
import VehicleDetails from '../Vehicle/VehicleDetails';
import { DownSortIcon, UpSortIcon } from '../../../UIComponents/Table/Table';
import CustomerEdit from './CustomerEdit';
import CustomerDetail from './CustomerDetail';
import {
  clearCVDataCache,
  countryOptions,
  customerInspectionQuery,
  defaultInspectionQuery,
  initialInfoEditForm,
  objectsAreEqual,
  suffixOptions,
  getCachedCVData,
} from '../../../helper/baseDataHelper';
import { APIContext, isAAASite, isSRPMICSite } from '../../../Data/API';
import {
  addressChangeChecker,
  addressVerification,
  customerFormValidation,
  fetchZip,
  taxExemptionApplication,
  validateContactInfo
} from '../../../helper/customerFormValidation';
import {
  isNullEmptyUndefined,
  upperCaseFirstLetter,
  customerRestructure,
  checkIfObjectHasKey,
  isEmptyObject,
  checkRequiredFields,
} from '../../../helper/validationHelper';
import getModalData, {
  getErrorModalData,
} from '../../../helper/modalDataHelper';
import {
  getUpdatedVehicleAssembly, getVehicleAssemblies, getVehicleInfo, vtvAircheckBaseData, isIncompleteVehicle, isIncompleteInspectionVehicle, getIncompleteInspectionVehicle
} from '../../../helper/vehicleFormValidation';
import { APIRouter } from '../../../Data/APIRouter';
import {
  addUpdateCustomerVehicle,
  deactivateOneTimeUseVehicles,
  isVehicleActive,
  isVehicleInactive,
  restructureImportedVehicle,
  splitActiveInactiveVehicles,
  vtvAircheckAddVehicle,
} from '../../../UIComponents/Modals/VehicleModals/StandardVehicle/VehicleHelper';
import { vehicleMatchUp } from '../../../helper/vehicleDetailsHelper';
import {
  deepObjectCopy,
  editFieldLengthMet,
  getCustomerVehicles,
  getFormattedUpdateCustomer,
  isB2BAddressValid,
  isViewAllVehiclesEnabled,
  processCustomerVehicles
} from '../../../helper/CustomerHelper';
import CustomerVehicles from './CustomerVehicles';

const SplitBlock = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  ${({ gap }) => gap && `gap:${gap}`}
`;
const VerticalBar = styled(DetailVBar)`
  position: absolute;
  top: 80px;
  right: 530px;
  height: calc(100% - 100px);
`;
const VehicleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-shrink: 1;
  ${({ justifyContent }) => `justify-content: ${justifyContent}`};
  margin: -20px 50px 0 0;
`;
const ViewMoreButton = styled.a`
  color: ${theme.colors.blue};
  text-transform: uppercase;
  border-bottom: 1px solid ${theme.colors.darkGray};
  padding-bottom: 8px;
  font-family: ${theme.fontFamily.semiBold};
  cursor: pointer;
`;
const ViewMoreButtonWrapper = styled.div`
  margin: 0px 0 10px 0;
  font-weight: ${theme.fontWeight.semiBold};
  font-size: ${theme.fontSize.large};
  text-align: center;
`;
const MoreInfoIcon = styled(UpSortIcon)`
  color: ${theme.colors.blue};
  margin-right: 0.3em;
  position: relative;
  top: 4px;
`;
const LessInfoIcon = styled(DownSortIcon)`
  color: ${theme.colors.blue};
  margin-right: 0.3em;
  position: relative;
  top: 4px;
`;
const NoVehiclesLabel = styled.p`
  font-size: ${theme.fontSize.modalTitle};
  margin-left: 30px;
`;

const ShippingAddress = styled(DetailSection)`
  width: 55%;
`;
const EmailAddresses = styled(DetailSection)`
  width: 55%;
`;
const EmailWrapper = styled.div`
  display: flex;
`;
const CustomerSummary = (props) => {
  const {
    aircheckSelected,
    applyCustomerVehicleError,
    csrSelected,
    customerInfo,
    deactivatedVehicles,
    gkTransactionCV,
    importedVehicle,
    isAllVehiclesView,
    isManualVtv,
    moduleName,
    navInfo,
    previousOrigin,
    searchOrigin,
    searchQuery,
    searchResults,
    selectedVehicle,
    setAircheckSelected,
    setCSRSelected,
    setCustomerInfo,
    setCustomerUpdate,
    setDeactivatedVehicles,
    setImportedVehicle,
    setIsAllVehiclesView,
    setIsModalOn,
    setIsToggle,
    setLoader,
    setModalDetails,
    setModuleName,
    setPreviousOrigin,
    setSearchOrigin,
    setIsManualVtv,
    setSearchQuery,
    setSelectedVehicle,
    setShowViewAllVehiclesLink,
    setShowDeactivatedVehicles,
    setTransactionCustomer,
    setVehicles,
    setVtvSelected,
    vtvSelected,
    setVtvCanSave,
    vtvCanSave,
    showDeactivatedVehicles,
    showViewAllVehiclesLink,
    shouldRunOnSelectedVehicleChange,
    vehSelected,
    vehicles,
  } = useContext(CVMContext);
  const { isBlocked, isDetailView } = props;
  const { getStateOrProvince, getStateOptions } = useContext(APIContext);

  const initialContactEditForm = (data) => {
    countryOptions.forEach((country) => {
      if (country.value === data?.country) data.country = country.label;
    });
    if (!isNullEmptyUndefined(data?.country) && typeof data?.country === 'object') {
      data.country = data?.country.label;
    }
    if (!isNullEmptyUndefined(data?.state) && typeof data?.state === 'object') {
      data.state = data?.state.value;
    }
    return {
      addressId: data?.addressId || constants.EMPTY_STRING,
      address1: data?.address1
        ? upperCaseFirstLetter(data?.address1)
        : constants.EMPTY_STRING,
      address2: data?.address2
        ? upperCaseFirstLetter(data?.address2)
        : constants.EMPTY_STRING,
      city: data?.city
        ? upperCaseFirstLetter(data?.city)
        : constants.EMPTY_STRING,
      country: data?.country
        ? countryOptions.filter(
          (country) => country?.label === data?.country
        )[0]
        : countryOptions[0],
      state: data?.state
        ? getStateOrProvince(
          countryOptions.filter((option) => option.label === data?.country)[0]
            ?.value,
          { value: data?.state }
        )[0]
        : constants.EMPTY_STRING,
      zip: data?.zip || constants.EMPTY_STRING,
      isAddressValidated: data?.isAddressValidated,
      phoneNumber: data?.phoneNumber || constants.EMPTY_STRING,
      phoneCountryCode: data?.phoneCountryCode || constants.UNITED_STATES,
      phoneId: data?.phoneId || constants.EMPTY_STRING,
      mobileNumber: data?.mobileNumber || constants.EMPTY_STRING,
      mobileCountryCode: data?.mobileCountryCode || constants.UNITED_STATES,
      mobileId: data?.mobileId || constants.EMPTY_STRING,
      workNumber: data?.workNumber || constants.EMPTY_STRING,
      workCountryCode: data?.workCountryCode || constants.UNITED_STATES,
      workId: data?.workId || constants.EMPTY_STRING,
      workExt: data?.workExt || constants.EMPTY_STRING,
      email: data?.email || constants.EMPTY_STRING,
      emailId: data?.emailId || constants.EMPTY_STRING,
    };
  };
  // Field Error Handling
  const [fieldErrors, setFieldErrors] = useState({});
  const [contactInfoErrorClicked, setContactInfoErrorClicked] = useState(false);
  const [initialInfo, setInitialInfo] = useState();
  const [infoFields, setInfoFields] = useState();
  const [primaryFields, setPrimaryFields] = useState();
  const [alternateFields, setAlternateFields] = useState();
  const [infoExpanded, setInfoExpanded] = useState(false);
  const editInformation = moduleName === constants.MODULE_EDIT_CUSTOMER;
  const [primaryEdit, setPrimaryEdit] = useState(false);
  const [alternateEdit, setAlternateEdit] = useState(false);
  const [contactRequiredMet, setContactReqiredMet] = useState(false);
  const [checked, setChecked] = useState(false);
  const [updatedFields, setUpdatedFields] = useState();
  const [isSRPMIC, setIsSRPMIC] = useState(false);
  const [isAAA, setIsAAA] = useState(false);
  const [srpmicOpen, setSRPMICOpen] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const storeState = localStorage.getItem('siteState');
  const [inspectionFields, setInspectionFields] = useState(null);
  const [initialInspection, setInitialInspection] = useState(null);
  const inspectionType = inspectionFields?.reason || constants.VTV_REASON;
  const bottomRef = useRef();
  let isInspection = vtvSelected || aircheckSelected || isManualVtv;

  const viewVehicleDetails = async (e, c, inspection) => {
    if (!c) return;
    try {
      //  TODO: this is a bit of a hack since the full API would return tire
      //  details with the vehicle itself...they wouldn't have to be fetched
      //  separately via another call. Remove this once schema is corrected.
      const tireDetails = await getVehicleInfo(c, setLoader);
      c.vehicleTireData = tireDetails?.vehicleTireData;

      const vehicle = {
        vehicleDetails: c,
        tireDetails,
        inspection
      };
      setSelectedVehicle(vehicle);
      return vehicle;
    } catch (err) {
      console.error(err);
    }
  };
  const onSelectedVehicleChange = async () => {
    // if there was a link prev then dont refresh
    // TODO: Should the first null below have real query parameters?
    /*
    const [customerVehicles, nonActiveVehicles] = await getCustomerVehicles(
      customerInfo.id,
      null,
      deactivateOneTimeUseVehicles,
      setLoader
    );

    setVehicles(customerVehicles);
    setDeactivatedVehicles(nonActiveVehicles);
    setShowViewAllVehiclesLink(
      isViewAllVehiclesEnabled(customerVehicles, selectedVehicle)
    );
    */
    if (!selectedVehicle) {
      return;
    }

    //  This is the actual selected vehicle stuff.
    await isIncompleteVehicle(selectedVehicle);

    await getVehicleInfo(selectedVehicle, setLoader, true);

    if (selectedVehicle?.inspection || isManualVtv) {
      // WIP: Need a better way to deep clone, this is needed for now since shallow
      const inspectionData = vtvAircheckBaseData(
        isManualVtv ? null : { ...selectedVehicle.inspection },
        selectedVehicle.vehicleDetails
      );
      setInspectionFields(deepObjectCopy(inspectionData));
      setInitialInspection(deepObjectCopy(inspectionData));
    }
  };

  const onLoadData = async () => {
    let customer,
      vehicle,
      customerVehicles,
      nonActiveVehicles,
      activeVehicles,
      inactiveVehicles,
      inspection,
      isB2B,
      isUsingCVCache;

    try {
      if (aircheckSelected || vtvSelected || isManualVtv) {
        isInspection = true;

      } else {
        setVtvCanSave(false);
        isInspection = false;
      }

      if ((csrSelected || vehSelected) && !customerInfo.id) {
        let custId;

        if (csrSelected) {
          custId = csrSelected.id || csrSelected.customerId;
        } else {
          custId = vehSelected.customerId;
        }

        let res;

        // When loading data if the customer is in our Apply C/V cache we can
        // skip the API call and use the cached data.
        const cachedCVData = custId ? getCachedCVData(custId, null) : null;
        if (API.utils.notEmpty(cachedCVData)) {
          console.log('Skipping customer fetch. Relying on CV cache.');
          res = cachedCVData;
          customer = res.customer;
        } else {
          res = await APIRouter(
            'C360',
            'getCustomer',
            custId,
            setLoader
          );

          if (!res?.customer) {
            return;
          }

          res.customer.id = res.customer.id || res.customer.customerId;
          if (!res?.customer?.id) {
            return;
          }

          customer = customerRestructure(res.customer);
        }

        isB2B = customer.customerType === constants.BUSINESS_CUSTOMER_TYPE;
        setIsBusiness(isB2B);
        if (isB2B && !isB2BAddressValid(customer)) {
          setPrimaryFields(initialContactEditForm(customer));
          setPrimaryEdit(true);
        }

        if (searchOrigin === constants.SEARCH_ORIGIN_CREATE_CUSTOMER) {
          // May cause a recursive invocation via useEffect() so we ensure that
          // we only call this if the id has changed for some reason.
          const csr = { id: customer.id || customer.customerId };
          if (csrSelected && csrSelected.id !== csr.id) {
            setCSRSelected(csr);
          }
          setVehicles([]);
        }

        const customerId = res.customer.id;

        // If there's a selected vehicle request (via GKInfo or URL param)
        // we want to see if we've got that vehicle cached in the CV cache.
        // If so we can skip querying for vehicles and just select it.

        if ((gkTransactionCV && gkTransactionCV?.vehicle?.customerVehicleID) ||
            env('url_vehicle_id')) {
          const veh = getCachedCVData(customerId,
            gkTransactionCV?.vehicle?.customerVehicleID || env('url_vehicle_id'));
          if (API.utils.notEmpty(veh)) {
            console.log('Skipping vehicle fetch. Relying on CV cache.');
            isUsingCVCache = true;
            customerVehicles = [veh.vehicle];
            setSelectedVehicle(veh.vehicle);
            setShowViewAllVehiclesLink(true);
          }
        }

        //  Since this is a 'one time use only' cache, we need to clear it
        //  after we use it once, otherwise we'd have to keep it up-to-date
        //  with every customer, vehicle, etc. edit.
        clearCVDataCache();

        if (!isUsingCVCache) {
          const queryVals = {
            defaultPageSize: 20,
            isActive: true,
            normalize: true,
          };

          customerVehicles = await APIRouter(
            'C360',
            'getCustomerVehicles',
            [customerId, queryVals],
            setLoader
          );

          // Added this VehicleMatch logic below if we get vehicle data
          // incomplete/bad data which has response with trim fields (eg:
          // missing vehicleId or relationshipId) then we are re-querying the
          // getCustomerVehicle api call by passing VIN/LicensePlate and fetch
          // right vehicle and unshift it to index 0 as below.
          const vehicleMatchIndex = customerVehicles.findIndex((veh) => {
            return (veh.vin && veh.vin === searchQuery.vin) ||
              (veh.licensePlate && veh.licensePlate === searchQuery.licensePlate);
          });
          let vehicleMatch;
          if (vehicleMatchIndex === -1) {
            vehicleMatch = await APIRouter(
              'C360',
              'getCustomerVehicle',
              [customerId, searchQuery],
              setLoader,
              false
            );
          } else {
            vehicleMatch = customerVehicles[vehicleMatchIndex];
          }
          // A little tricky here. We just re-queried for customer which
          // means we'll end up without vehicle(s) in any prior search results.
          // The tricky part is that vehicle-driven searches will have vehicles
          // but the pure customer-driven searches won't. And VTV is a whole
          // different critter entirely.
          if (searchResults && searchResults.length) {
            const customers = searchResults.filter(c => {
              return c?.customerId === customer.id;
            });
            if (customers.length && customers?.[0]?.vehicleCollection?.length) {
              // Put the results on the front so it's found quickly and is
              // visible.

              // Find the index of the match
              if (!isNullEmptyUndefined(vehicleMatch)) {
                // Remove the match from its current position
                customerVehicles.splice(vehicleMatchIndex, 1);
                // Add the match to the beginning of the array
                customerVehicles.unshift(vehicleMatch);
              }
            }
          }

          await processCustomerVehicles(customerVehicles);
          [activeVehicles, inactiveVehicles] = splitActiveInactiveVehicles(customerVehicles);
          //  If we got less than 3 active vehicles, if we get 3 here, then at least
          //  1 has to be inactive.
          if (activeVehicles?.length < 3) {
            const inactivesQueryVals = { ...queryVals };

            //  NB: When this flag is *missing* we are asking for both active and
            //  inactive, which should let us verify at least one inactive.
            delete inactivesQueryVals.isActive;

            customerVehicles = await APIRouter(
              'C360',
              'getCustomerVehicles',
              [customerId, inactivesQueryVals],
              setLoader
            );

            //  See if we got an inactives.
            [, inactiveVehicles] = splitActiveInactiveVehicles(customerVehicles);

            await processCustomerVehicles(inactiveVehicles, deactivateOneTimeUseVehicles);
          }

          setVehicles(activeVehicles || []);
          setDeactivatedVehicles(inactiveVehicles || []);

          //  If we got a full page of data, then we have to show the link.
          setShowViewAllVehiclesLink(activeVehicles?.length >= 3);
        }
      } else {
        customer = customerInfo;
        customerVehicles = vehicles || [];
        nonActiveVehicles = deactivatedVehicles || [];
        isB2B = customer.customerType === constants.BUSINESS_CUSTOMER_TYPE;
        setIsBusiness(isB2B);

        //  If we got a full page of data, then we have to show the link.
        setShowViewAllVehiclesLink(customerVehicles?.length >= 3);
      }
    } catch (e) {
      console.error(e);
    }
    if (vehSelected && !selectedVehicle) {
      try {
        let restructuredVehicle = {
          ...vehSelected.vehicleCollection[0]?.vehicleItem,
        };
        if (vehSelected.type !== constants.VEHICLE_TYPE_GENERIC) {
          const ymmVehicle = {
            ...restructuredVehicle,
            ...vehSelected.vehicleCollection.find(
              (veh) => veh.vehicleItem.year &&
                veh.vehicleItem.make &&
                veh.vehicleItem.model
            )?.vehicleItem,
          };

          restructuredVehicle = ymmVehicle || restructuredVehicle;
        }

        restructuredVehicle.id = restructuredVehicle.id || restructuredVehicle.vehicleId || customerVehicles?.[0].vehicleId;
        const queryVehicle = customerVehicles.find((veh) => {
          if (isVehicleInactive(veh)) {
            return false;
          }

          if (
            searchQuery.vin &&
            veh?.vin === searchQuery.vin
          ) {
            // we have added fallback to select CustomerVehicles[0] if search Result restructured vehicle is missing data
            return vehicleMatchUp(restructuredVehicle, veh) || customerVehicles[0];
          }
          if (
            searchQuery.licensePlate &&
            veh.licensePlateNumber ===
              searchQuery.licensePlate?.toUpperCase()
          ) {
            // we have added fallback to select CustomerVehicles[0] if search Result vehicle is missing data
            return vehicleMatchUp(restructuredVehicle, veh) || customerVehicles[0];
          }
          if (
            searchQuery.vehicleId &&
            veh.vehicleId ===
              searchQuery?.vehicleId
          ) {
            return vehicleMatchUp(restructuredVehicle, veh) || customerVehicles[0];
          }
        });
        if (queryVehicle) {
          viewVehicleDetails(null, queryVehicle);
        }
      } catch (e) {
        console.error(e);
      }
    }
    if (isInspection) {
      setInspectionFields(null);
      setInitialInspection(null);
      if (!selectedVehicle?.inspection && !isManualVtv) {
        try {

          let vehicleMatch;
          const inspectionId = vtvSelected
            ? vtvSelected.inspectionId
            : aircheckSelected.inspectionId;
          inspection = await APIRouter(
            'VTV',
            'getVTVInspections',
            { inspectionId },
            setLoader
          ).then(async (items) => {
            if (checkIfObjectHasKey(items, 'getVTVInspections') || !items) {
              return console.error('Inspection not found!');
            }
            return items[0];
          });
          // NOTE: If theres a customer id which means that its coming from customer summary will not do customer match up
          if (!customer?.id) {
            const inspectionIdCustomer = await APIRouter(
              'C360',
              'getCustomer',
              inspection.customer?.customerId,
              setLoader
            );
            if (!isNullEmptyUndefined(inspectionIdCustomer?.customer)) {
              customer = customerRestructure(inspectionIdCustomer?.customer);
            } else {
              // Constructed customer object
              const customerQuery = customerInspectionQuery(
                inspection?.customer
              );
              delete customerQuery.phoneType;
              const res = await APIRouter(
                'C360',
                'getCustomers',
                customerQuery,
                setLoader
              );
              // Happy path: customer is found in C360
              if (res && res?.length === 1) {
                const cust = await APIRouter(
                  'C360',
                  'getCustomer',
                  res[0]?.id,
                  setLoader
                );
                customer = customerRestructure(cust.customer);
              } else if (res && res?.length > 1) {
                // Here is where i will set the results
                setSearchOrigin(null);
                setSearchQuery(customerQuery);
                setSearchOrigin(constants.SEARCH_ORIGIN_CUSTOMER);
                return;
              } else {
                // NOTE: the query did not return only one customer
                const modalData = getErrorModalData(
                  constants.CUSTOMER_NOT_FOUND
                );
                setModalDetails(modalData);
                setIsModalOn(true);
                return;
              }
            }
          } else {
            // This is for the fields that are missing when the customer is just passthrough with new
            inspection.customer.firstName = customer.firstName;
            inspection.customer.lastName = customer.lastName;
            inspection.customer.email = customer.email;
            inspection.customer.phone = customer.phone;
          }
          const accountClosed = !isNullEmptyUndefined(customer.blockedReasonCode) && !isNullEmptyUndefined(customer.postingBlock);
          // 2. GET customer Vehicles
          if (!accountClosed) {
            vehicle = inspection?.vehicle;
            // Device vtv inspections have a fallback generic vin. This breaks CVM functionality
            if (vehicle?.vin && vehicle?.vin.includes('DTCVIN')) {
              vehicle.vin = constants.EMPTY_STRING;
            }

            customerVehicles = await APIRouter(
              'C360',
              'getCustomerVehicles',
              [customer.id],
              setLoader
            );

            //  See if we got an active & deactive in inspection -NL
            [activeVehicles, inactiveVehicles] = splitActiveInactiveVehicles(customerVehicles);
            await processCustomerVehicles(inactiveVehicles, deactivateOneTimeUseVehicles);
            setVehicles(activeVehicles || []);
            setDeactivatedVehicles(inactiveVehicles || []);

            // NOTE: this is hacky, but accounting for Zebra inputing vin's as customerVehicleId's
            const inspectionVehicleId = inspection?.customerVehicleId?.length > 15 ? inspection.vehicle.vehicleId : inspection?.customerVehicleId;
            // Adding new direct query for customer vehicle in inspection -NL
            vehicleMatch = await APIRouter(
              'C360',
              'getCustomerVehicle',
              [inspection.customer?.customerId, inspectionVehicleId],
              setLoader,
              false
            );

            // If the vehicle is not found with direct vehicle id in inspection, also need to check both since not found is nested object
            if (vehicleMatch?.getCustomerVehicle === null) {
              vehicleMatch = activeVehicles?.find((veh) => veh?.vehicleId === inspection?.customerVehicleId);
              // NOTE: No longer doing the match up based on YMM switching to license/vin
              vehicleMatch = activeVehicles?.find(
                (veh) => (vehicle?.licensePlate &&
                  veh.licensePlateNumber === vehicle.licensePlate) ||
                (vehicle?.vin && veh.vin === vehicle.vin)
              );
            }
            if (!vehicleMatch && customer.customerType === constants.STANDARD_CUSTOMER_TYPE
            ) {
              vehicleMatch = activeVehicles?.find(
                (veh) => veh.year?.toString() === vehicle.year &&
                veh.make?.toLowerCase() === vehicle.make?.toLowerCase() &&
                veh?.model?.toLowerCase() === vehicle?.model?.toLowerCase() &&
                (veh.trim?.toLowerCase() === vehicle?.trimName?.toLowerCase() ||
                  veh?.trimId === vehicle?.trimId)
              );
            }
            if (!selectedVehicle && !isManualVtv) {
              if (!vehicleMatch) {
                if (isIncompleteInspectionVehicle(vehicle)) {
                // NOTE: Checks if the vehicle is incomplete, pops up the add the vehicle modal.
                  vehicle = getIncompleteInspectionVehicle(vehicle, false, getStateOptions);
                  editVehicle(vehicle);
                } else {
                // Unhappy path: No vehicle match found in customer vehicles
                  const vehicleParams = vtvAircheckAddVehicle(vehicle, getStateOptions);
                  vehicleParams[0].assemblyCollection = await getVehicleAssemblies(vehicleParams[0], setLoader);
                  // Add throw error for when there is no assemlby match: Need more failed inspections to test modal
                  // if (isEmptyObject(vehicleParams[0].assemblyCollection)) throw new Error(constants.VEHICLE_ADD_ERROR);
                  const assemblyMatch = vehicleParams[0]?.assemblyCollection?.filter(assembly => {
                    if (vehicle?.isStaggered) {
                      return assembly.assembly[0]?.tireSize?.replace(/[a-z]/gi, '').includes(vehicle?.frontAssembly?.replace(/[a-z]/gi, '')) &&
                assembly.assembly[1]?.tireSize?.replace(/[a-z]/gi, '').includes(vehicle?.rearAssembly?.replace(/[a-z]/gi, ''));
                    }
                    return assembly.assembly?.tireSize?.replace(/[a-z]/gi, '').includes(vehicle?.frontAssembly?.replace(/[a-z]/gi, ''));
                  });
                  // if (!assemblyMatch) throw new Error(constants.VEHICLE_ADD_ERROR);
                  vehicleParams[0] = getUpdatedVehicleAssembly(vehicleParams[0], assemblyMatch[0]);
                  // removes the other format "assembly"
                  vehicleParams[0].assemblyCollection = vehicleParams[0].assemblyCollection?.filter((assembly, i) => assembly.assemblyItem && assembly.assemblyItem);
                  // Uses the addUpdate customer vehicle call to add if there was no match
                  await addUpdateCustomerVehicle(null, true, false, { ...customer }, null, setLoader, setIsModalOn, moduleName, setModuleName, setShowDeactivatedVehicles, setSelectedVehicle, vehicleParams[1], vehicleParams[0], false, null, inspection, null, null, null, setImportedVehicle, false);
                }
              }
              // NOTE: isIncompleteVehicle is transforming the vehicle parameter causing generic to break
              const isIncompleVehicle = vehicleMatch?.type !== 'Generic' ? await isIncompleteVehicle(vehicleMatch) : false;
              // VehicleMatch found but is an incomplete vehicle from inspection
              if (vehicleMatch && isIncompleVehicle) {
                vehicle = getIncompleteInspectionVehicle(vehicleMatch, true);
                editVehicle(vehicle);
              } else if (vehicleMatch) {
              // happy path vehicle match was found and there was complete vehicle
                viewVehicleDetails(null, vehicleMatch, inspection);
              }
            }
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        onSelectedVehicleChange();
      }
    }
    if (API.utils.notValid(customer.taxExempt)) {
      let taxInfo;
      if (customer.customerType !== constants.BUSINESS_CUSTOMER_TYPE) {
        taxInfo =  await APIRouter('TAX', 'getTaxExemptInfo', [[{ customerId: customer?.id, state: storeState, customerType: customer?.customerType }]], setLoader);
      }
      const expirationDate = moment().endOf('day').add(1, 'day').format('YYYY-MM-DD');
      if (taxInfo?.taxCertificateNumber) {
        if (
          (taxInfo?.expirationDate &&
            moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(
              expirationDate
            )) ||
          taxInfo.status === 'EXPIRED'
        ) {
          taxInfo.status = constants.STATUS_EXPIRED;
        }
        customer.taxExempt = taxInfo;
      }
      if (checkIfObjectHasKey(taxInfo, constants.INVALID_STATE_RES)) {
        customer.taxExempt = { status: constants.STATUS_STATE_MISMATCH };
      }
      if (customer.customerType === constants.BUSINESS_CUSTOMER_TYPE && customer.taxClassificationCode === '0') {
        customer.taxExempt = {
          status: 'ACCEPTED',
          state: localStorage.getItem('siteState'),
        };
      }

      // Default to an empty object so we stop querying for folks without one.
      customer.taxExempt = customer.taxExempt || {};
    } else {
      customer.taxExempt = customer.taxExempt || customerInfo.taxExempt || {};
    }
    // NOTE: Sets the SRPMIC & AAA functionality, strips customer info from sending to GK
    if (localStorage.getItem('SRPMIC') === 'true' && !isNullEmptyUndefined(localStorage.getItem('SRPMICNumber'))) {
      const srpmicItem = localStorage.getItem('SRPMICNumber');
      const srpmicArray = srpmicItem.split(':');
      if (srpmicArray[0] !== customer.id) {
        localStorage.removeItem('SRPMICNumber');
      } else {
        customer.SRPMICNumber = srpmicArray[1];
      }
    }

    if (customer) {
      isB2B = customer.customerType === constants.BUSINESS_CUSTOMER_TYPE;
      setIsBusiness(isB2B);
    }
    // NOTE: Divided SRPMIC & AAA since stores may need one or the other
    if (isSRPMICSite(localStorage.getItem('siteId'))) {
      setIsSRPMIC(true);
    }
    if (isAAASite(localStorage.getItem('siteId'))) {
      setIsAAA(true);
    } else {
      customer.alternateIdentifierCollection =
        customer.alternateIdentifierCollection?.filter(
          (altItem) => altItem.alternateIdentifierItem.name !== 'AAA'
        );
      customer.AAA = '';
    }
    setCustomerInfo({
      ...customer,
      isGKValid:
        customer?.customerType === constants.STANDARD_CUSTOMER_TYPE
          ? !isNullEmptyUndefined(customer.address1) &&
            !isNullEmptyUndefined(customer.city) &&
            !isNullEmptyUndefined(customer.state) &&
            !isNullEmptyUndefined(customer.country) &&
            !isNullEmptyUndefined(customer.zip) &&
            customer.phoneCollection?.length > 0
          : customer.addressCollection?.length > 0
            ? !isNullEmptyUndefined(
              customer.addressCollection[0]?.addressItem?.addressLine1
            ) &&
            !isNullEmptyUndefined(
              customer.addressCollection[0]?.addressItem?.city
            ) &&
            !isNullEmptyUndefined(
              customer.addressCollection[0]?.addressItem?.region
            ) &&
            !isNullEmptyUndefined(
              customer.addressCollection[0]?.addressItem?.country
            ) &&
            !isNullEmptyUndefined(
              customer.addressCollection[0]?.addressItem?.postalCode &&
                customer.phoneCollection?.length > 0
            )
            : false,
    });
  };

  const onLoadEdit = () => {
    const customer = customerInfo;
    const primary = initialContactEditForm(customer);
    const alternate = initialContactEditForm(customer?.alternate);
    const info = initialInfoEditForm({ ...customer, ...alternate });
    setSRPMICOpen(!isNullEmptyUndefined(customerInfo?.SRPMICNumber));
    setInitialInfo({ ...info, ...primary, alternate: { ...alternate } });
    setInfoFields(info);
    !isBusiness && setPrimaryFields(primary);
    setAlternateFields(alternate);
  };

  const postRenderNavigate = async () => {

    const hasNavVehicle = () => {
      return !isNullEmptyUndefined(navInfo.params.vehicleId || navInfo.params.vin);
    };

    const getNavVehicle = () => {
      if (vehSelected) {
        return vehSelected?.vehicleCollection?.[0];
      }
    };

    if (isNullEmptyUndefined(navInfo)) {
      return;
    }

    switch (navInfo.target) {
      case 'customer_edit':
        editCustomer();
        break;
      case 'vehicle_edit':
        //  We should trigger vehicle edit here, but it doesn't work. Too many
        //  useEffect refreshes?
        if (hasNavVehicle()) {
          const vehicle = await viewVehicleDetails(null, getNavVehicle(), null);
          setTimeout(() => {
            setSelectedVehicle(vehicle);
            editVehicle(vehicle);
          }, 5000);
        }
        break;
      case 'customer_summary':
      case 'vehicle_select':
        if (hasNavVehicle()) {
          await viewVehicleDetails(null, getNavVehicle(), null);
        }
        break;
      default:

    }
  };
  // resetting customer info on load
  useEffect(() => {
    if (moduleName === constants.MODULE_CUSTOMER_SUMMARY) {
      setIsAllVehiclesView(navInfo?.target === 'customer_vehicles');
    }
    if (!editInformation) {
      onLoadData();
    }
  }, [moduleName, csrSelected, vtvSelected, aircheckSelected, isManualVtv]);

  useEffect(() => {
    applyCustomerVehicleError && editVehicle();
  }, [applyCustomerVehicleError]);

  const isApplyEditsValid = () => {
    return isEmptyObject(editFieldLengthMet(infoFields));
  };
  const renderFieldErrors = () => {
    setFieldErrors(editFieldLengthMet(infoFields));
  };

  useEffect(() => {
    if (editInformation || (isBusiness && !isB2BAddressValid(customerInfo))) {
      if (!infoFields) {
        onLoadEdit();
      }
      if (
        !primaryEdit &&
        !alternateEdit &&
        !objectsAreEqual(initialInfo, {
          ...infoFields,
          ...primaryFields,
          alternate: { ...alternateFields },
        })
      ) {
        const isValid = isApplyEditsValid();
        setCustomerUpdate(true);
        const modalData = getModalData(constants.CUSTOMER_EDIT_MODAL);
        modalData.data = { ...initialInfo };
        modalData.isValid = isValid;
        modalData.renderFieldErrors = renderFieldErrors;
        modalData.data = {
          ...infoFields,
          ...primaryFields,
          alternate: { ...alternateFields },
        };
        setModalDetails(modalData);
      } else {
        setCustomerUpdate(false);
      }
      if (primaryEdit || alternateEdit) {
        if (
          checkRequiredFields(
            primaryEdit ? { ...primaryFields } : { ...alternateFields },
            'Contact Info',
            !isB2BAddressValid(customerInfo)
          )
        ) {
          setContactReqiredMet(true);
        } else {
          setContactReqiredMet(false);
        }
      }
      // Removing to test if it doesn't work, I will put back in
      // bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (!isBusiness) {
      setInfoFields();
      setPrimaryFields();
      setAlternateFields();
      setUpdatedFields();
      setInitialInfo();
      setPrimaryEdit(false);
      setAlternateEdit(false);
    }
  }, [moduleName, primaryEdit, alternateEdit, infoFields, primaryFields, alternateFields]);
  useEffect(() => {
    if (primaryEdit || alternateEdit) {
      fetchZip(
        primaryEdit ? primaryFields : alternateFields,
        primaryEdit ? setPrimaryFields : setAlternateFields,
        setLoader,
        getStateOptions
      );
    }
  }, [primaryFields?.zip, alternateFields?.zip]);

  useEffect(() => {
    if (selectedVehicle && selectedVehicle.vehicleDetails?.isIncomplete) {
      editVehicle();
    } else if (!editInformation && (customerInfo.id || selectedVehicle?.inspection || isManualVtv) && !selectedVehicle?.vehicleDetails?.isCarryOut) {
      onSelectedVehicleChange();
    }
  }, [selectedVehicle, isManualVtv]);

  useEffect(async () => {
    await postRenderNavigate();
  }, []);

  const setExpandedInfo = () => {
    // To Do: add sorting criteria
    setInfoExpanded(!infoExpanded);
  };
  const expanded = () => {
    return infoExpanded ? <MoreInfoIcon /> : <LessInfoIcon />;
  };
  const goToResultScreen = async (isVTV) => {
    setSearchQuery(defaultInspectionQuery());
    setIsToggle(isVTV);
    if (vtvSelected || aircheckSelected) {
      let previousInspectionOrigin;
      if (
        previousOrigin.origin === constants.SEARCH_ORIGIN_CUSTOMER ||
        previousOrigin.origin === constants.SEARCH_ORIGIN_VIN ||
        previousOrigin.origin === constants.SEARCH_ORIGIN_LICENSE_PLATE
      ) {
        previousInspectionOrigin = {
          origin: previousOrigin.origin,
          searchQuery: previousOrigin.searchQuery,
        };
        previousInspectionOrigin.results = previousOrigin.results;
      } else {
        previousInspectionOrigin = { origin: searchOrigin, searchQuery };
        if (vtvSelected?.inspectionId || aircheckSelected?.inspectionId) {
          previousInspectionOrigin.data = vtvSelected || aircheckSelected;
          previousInspectionOrigin.results = searchResults;
        }
      }
      setPreviousOrigin(previousInspectionOrigin);
    } else {
      setPreviousOrigin({
        origin: searchOrigin,
        searchQuery,
        results: searchResults,
      });
      setVtvSelected(null);
      setAircheckSelected(null);
    }
    setSearchOrigin(
      isVTV ? constants.SEARCH_ORIGIN_VTV : constants.SEARCH_ORIGIN_AIRCHECK
    );
  };

  const vtvAircheckTitleButtons = [
    {
      buttonType: constants.BUTTON_TYPE_CREATE,
      buttonName: constants.TITLE_SELECT_BY_VTV,
      buttonClick: () => {
        goToResultScreen(true);
      },
    },
    {
      buttonType: constants.BUTTON_TYPE_CREATE,
      buttonName: constants.TITLE_SELECT_BY_AIRCHECK,
      buttonClick: () => {
        goToResultScreen(false);
      },
    },
  ];
  const addNewVehicle = () => {
    const popAddNewVehicleModal = (useImportedVehicle) => {
      setModalDetails({
        type: constants.MODAL_TYPE_ADD_NEW_VEHICLE,
        subTitle: constants.ADD_NEW_VEHICLE_SUBTITLE,
        useImportedVehicle,
      });
    };

    // pop imported vehicle modal if applicable
    if (importedVehicle) {
      const modalData = getModalData(constants.IMPORTED_VEHICLE_MODAL, null, (useImportedVehicle) => popAddNewVehicleModal(useImportedVehicle));
      restructureImportedVehicle(importedVehicle, 'string');
      modalData.modalBody = (
        <Vehicle vehicle={importedVehicle} onSelect={() => {}} />
      );
      setModalDetails(modalData);
      setIsModalOn(true);
      return;
    }

    popAddNewVehicleModal();
    setIsModalOn(true);
  };

  const editVehicle = (vehicle) => {
    let selectedVehicleCopy = JSON.parse(JSON.stringify(selectedVehicle));
    if (vehicle?.vehicleDetails?.isInspectionVehicle) selectedVehicleCopy = deepObjectCopy(vehicle);
    setModalDetails({
      type: constants.MODAL_TYPE_ADD_NEW_VEHICLE,
      data: selectedVehicleCopy,
    });
    setIsModalOn(true);
  };

  if (showDeactivatedVehicles) {
    setModuleName(
      vehicles.length > 2 && moduleName !== constants.MODULE_CUSTOMER_SUMMARY
        ? constants.MODULE_CUSTOMER_VEHICLES
        : constants.MODULE_CUSTOMER_SUMMARY
    );

    return <DeactivatedVehicles vehicles={deactivatedVehicles} />;
  }
  const titleButtonsArr = [
    {
      buttonType: constants.BUTTON_TYPE_CREATE,
      buttonName: constants.ADD_NEW_VEHICLE,
      buttonClick: isBlocked ? null : () => addNewVehicle(),
    },
    deactivatedVehicles?.length >= 1
      ? {
        buttonType: constants.BUTTON_TYPE_VIEW,
        buttonName: constants.VIEW_DEACTIVATED_VEHICLES,
        buttonClick: () => setShowDeactivatedVehicles(!showDeactivatedVehicles),
      }
      : null,
  ];
  const editCustomer = (e, hasErrors = false) => {
    setContactInfoErrorClicked(hasErrors);
    setFieldErrors({});
    setModuleName(constants.MODULE_EDIT_CUSTOMER);
  };

  const backToCustomerSummary = () => {
    if (vtvCanSave) {
      setIsModalOn(true);
      setModalDetails(getModalData(constants.MODAL_TYPE_SUMMARY));
    } else {
      isInspection = false;
      setIsManualVtv(false);
      setVtvSelected(null);
      setAircheckSelected(null);
    }
  };
  const cancelContactEdit = () => {
    if (primaryEdit || alternateEdit) {
      setFieldErrors({});
      if (updatedFields) {
        if (primaryEdit) {
          setPrimaryFields(initialContactEditForm(updatedFields.primaryFields));
        } else {
          setAlternateFields(
            initialContactEditForm(updatedFields.alternateFields)
          );
        }
      } else if (primaryEdit) {
        setPrimaryFields(initialContactEditForm(customerInfo));
      } else setAlternateFields(initialContactEditForm(customerInfo.alternate));
      if (primaryEdit) {
        setPrimaryEdit(false);
      } else setAlternateEdit(false);
    }
  };
  // edit contact
  const editPrimary = () => {
    setFieldErrors({});
    if (!alternateEdit && !primaryEdit) {
      setAlternateEdit(false);
      setPrimaryEdit(true);
    }
    if (!primaryEdit && alternateEdit) {
      cancelContactEdit();
      setPrimaryEdit(true);
      setAlternateEdit(false);
    } else {
      setPrimaryEdit(true);
    }
  };
  const clearAlternateEdit = () => {
    const modalData = getModalData(constants.MODAL_TYPE_CLEAR_EDIT);
    modalData.isClearAlternate = true;
    modalData.data = {
      ...infoFields,
      ...primaryFields,
      alternate: { ...alternateFields },
    };
    setIsModalOn(true);
    setModalDetails(modalData);
  };
  const editAlternate = () => {
    setFieldErrors({});
    if (!alternateEdit && !primaryEdit) {
      setAlternateEdit(true);
    }
    if (primaryEdit && !alternateEdit) {
      cancelContactEdit();
      setPrimaryEdit(false);
      setAlternateEdit(true);
    } else {
      setAlternateEdit(true);
    }
  };

  const handleInput = (value, fieldName) => {
    const inputValue = customerFormValidation(value, fieldName);
    setInfoFields({ ...infoFields, [fieldName]: inputValue });
    if (localStorage.getItem('SRPMIC') === 'true' && fieldName === 'SRPMICNumber') {
      localStorage.setItem('SRPMICNumber', `${customerInfo.id}:${value}`);
    }
  };
  // Here is where the try catch statement needs to reside
  const handleContactInput = async (value, fieldName) => {
    if (fieldName.includes('contactCheckBox')) {
      const formType = fieldName.slice(-1);
      if (formType === 1 && validateContactInfo(primaryFields)) {
        setCustomerUpdate(true);
        setChecked(!checked);
        setTransactionCustomer(value);
      } else if (validateContactInfo(alternateFields)) {
        setCustomerUpdate(true);
        setChecked(!checked);
        setTransactionCustomer(value);
      } else {
        setCustomerUpdate(false);
      }
    } else {
      const inputValue = customerFormValidation(value, fieldName, primaryFields?.country?.value);
      primaryEdit
        ? setPrimaryFields({ ...primaryFields, [fieldName]: inputValue })
        : setAlternateFields({ ...alternateFields, [fieldName]: inputValue });
    }
  };

  const closeState = () => {
    primaryEdit
      ? setPrimaryFields({ ...primaryFields, state: constants.EMPTY_STRING })
      : setAlternateFields({ ...alternateFields, state: constants.EMPTY_STRING  });
  };

  const closeSuffix = () => {
    setInfoFields({ ...infoFields, suffix: constants.EMPTY_STRING });
  };


  const updateDropdown = (value, fieldName) => {
    let item,
      noOptionFound = false;
    if (fieldName === 'suffix') {
      item = suffixOptions.filter((option) => option.label === value?.label);
      if (item.length === 0) {
        item = [
          {
            value: isNullEmptyUndefined(value) ? constants.EMPTY_STRING : value,
            label: isNullEmptyUndefined(value) ? constants.EMPTY_STRING : value,
          },
        ];
        noOptionFound = true;
      }
      if (value && fieldName && item) {
        if (noOptionFound) {
          if (infoFields.suffix?.label !== item[0].label) {
            setInfoFields({ ...infoFields, [fieldName]: item[0].label });
          }
        } else {
          setInfoFields({ ...infoFields, [fieldName]: item[0] });
        }
      } else {
        setInfoFields({ ...infoFields, [fieldName]: constants.EMPTY_STRING });
      }
    }
    if (primaryEdit || alternateEdit) {
      const updatedFields = primaryEdit
        ? { ...primaryFields }
        : { ...alternateFields };
      switch (fieldName) {
        case 'phoneCountryCode':
          item = [value];
          updatedFields.phoneNumber = constants.EMPTY_STRING;
          break;
        case 'mobileCountryCode':
          item = [value];
          updatedFields.mobileNumber = constants.EMPTY_STRING;
          break;
        case 'workCountryCode':
          item = [value];
          updatedFields.workNumber = constants.EMPTY_STRING;
          break;
        case 'state':
          item = getStateOrProvince(
            primaryEdit
              ? primaryFields?.country?.value
              : alternateFields?.country?.value,
            value
          );
          break;
        case 'country':
          item = [
            countryOptions.filter((option) => option.label === value.label)[0],
          ];
          if (
            (primaryEdit || alternateEdit) &&
            value.label !== primaryFields.country
          ) {
            updatedFields.zip = constants.EMPTY_STRING;
            updatedFields.state = constants.EMPTY_STRING;
            updatedFields.city = constants.EMPTY_STRING;
          }
          break;
        default:
          return null;
      }
      if (value && fieldName && item) {
        primaryEdit
          ? setPrimaryFields({ ...updatedFields, [fieldName]: item[0] })
          : setAlternateFields({ ...updatedFields, [fieldName]: item[0] });
      }
    }
    return null;
  };
  const onSubmitAddress = async (value) => {
    if (!isB2BAddressValid(customerInfo) && customerInfo?.customerType === constants.BUSINESS_CUSTOMER_TYPE) {
      // TO DO: Export the address item collection construct to avoid calling again
      const restructuredCustomer = getFormattedUpdateCustomer(
        initialContactEditForm({ ...primaryFields, ...value }),
        customerInfo
      );
      const B2BCustomerRequest = {
        id: customerInfo.id,
        customerType: 'B2B',
        addressCollection: restructuredCustomer.addressCollection,
      };
      B2BCustomerRequest.addressCollection[0].addressItem.addressId =
        customerInfo?.addressCollection?.[0].addressItem.addressId ||
        constants.EMPTY_STRING;
      B2BCustomerRequest.addressCollection[0].addressItem.relationshipId =
        customerInfo?.addressCollection?.[0].addressItem.relationshipId ||
        constants.EMPTY_STRING;
      const res = await APIRouter(
        'C360',
        'updateCustomer',
        B2BCustomerRequest,
        setLoader,
        false
      );
      setCustomerInfo({});
      setCSRSelected({ id: res.customer.customerId });
      setModuleName(constants.MODULE_CUSTOMER_SUMMARY);
      setIsModalOn(false);
    }
    if (primaryEdit) {
      setPrimaryEdit(false);
      setPrimaryFields(initialContactEditForm({ ...primaryFields, ...value }));
      setUpdatedFields({ ...updatedFields, primaryFields, alternateFields });
    }
    if (alternateEdit) {
      setAlternateEdit(false);
      setAlternateFields(
        initialContactEditForm({ ...alternateFields, ...value })
      );
      setUpdatedFields({ ...updatedFields, primaryFields, alternateFields });
    }
    setIsModalOn(false);
  };

  const updateContactClick = async () => {
    // Handle the field length met
    const errorMesssages = editFieldLengthMet(
      primaryEdit ? primaryFields : alternateFields,
      true
    );
    if (isEmptyObject(errorMesssages)) {
      // TO DO: Update the field states to use one each
      if (
        (!updatedFields &&
          addressChangeChecker(
            primaryEdit ? primaryFields : alternateFields,
            primaryEdit ? initialInfo : initialInfo?.alternate
          )) ||
        (updatedFields &&
          addressChangeChecker(
            primaryEdit ? primaryFields : alternateFields,
            primaryEdit
              ? updatedFields.primaryFields
              : updatedFields.alternateFields
          ))
      ) {
        if (primaryEdit || alternateEdit) {
          const data = await addressVerification(
            primaryEdit ? primaryFields : alternateFields,
            setLoader
          );
          data.onEdit = primaryEdit ? editPrimary : editAlternate;
          if (data.length === 1) {
            onSubmitAddress(data[0]);
          } else {
            data.onSubmit = onSubmitAddress;
            setModalDetails(data);
            setIsModalOn(true);
          }
        }
      } else {
        const {
          addressId,
          address1,
          address2,
          state,
          zip,
          country,
          isAddressValidated,
        } = primaryEdit ? primaryFields : alternateFields;
        onSubmitAddress({
          addressId,
          address1,
          address2,
          state,
          zip,
          country,
          isAddressValidated,
        });
      }
    }
    setFieldErrors(errorMesssages);
  };
  const renderSRPMIC = () => {
    if (srpmicOpen) {
      setInfoFields({ ...infoFields, SRPMICNumber: constants.EMPTY_STRING });
      localStorage.removeItem('SRPMICNumber');
      setSRPMICOpen(false);
    } else {
      setInfoFields({ ...infoFields, SRPMICNumber: constants.EMPTY_STRING });
      setSRPMICOpen(true);
    }
  };
  const emailInvoiceRequired = customerInfo.contactCollection?.filter(
    (p) => p.contactItem.isEmailInvoiceRequired === true
  );
  const isSinglePoint = customerInfo.contactCollection?.find((d) => d.contactItem.isSinglePointOfContactPurchasing === true);
  const businessContacts = (customer) => {
    const regexp = new RegExp(/alternate|apContact/, 'i');
    const keyNames = Object.keys(customerInfo).filter((key) => regexp.test(key)
    );
    return keyNames.map((key, i) => {
      const alternate = key === 'alternate';
      return (
        <React.Fragment key={i + key}>
          {alternate && (
            <EmailWrapper>
              <CustomerDetail
                width="200px"
                isBold
                infoName={constants.TITLE_ALTERNATE}
              />
              <CustomerDetail
                width="150px"
                infoName={`${upperCaseFirstLetter(
                  customer[key]?.firstName
                )} ${upperCaseFirstLetter(customerInfo[key]?.lastName)}`}
              />
              <CustomerDetail infoName={customerInfo[key]?.email} />
            </EmailWrapper>
          )}
        </React.Fragment>
      );
    });
  };

  const openTaxWindow = async () => {
    if (!customerInfo.addressCollection) {
      !infoExpanded && setInfoExpanded(true);
      !isBusiness && editCustomer();
      return;
    }


    const res = await taxExemptionApplication(
      { ...customerInfo },
      getStateOptions,
      setLoader
    );
    if (isNullEmptyUndefined(res)) return;

    const newTaxWindow = async () => {
      setLoader({
        isLoading: true,
        loadingText: constants.LOADER_TAX_WAIT_FOR_CLOSE,
      });
      const newWindow = window.open(
        res,
        '',
        `height=${window.screen.height},width=${window.screen.width},top=0,left=0`
      );
      if (!newWindow) {
        console.error('pop-ups are blocked');
        return;
      }
      newWindow?.moveTo(0, 0);

      let newWinInterval;
      newWinInterval = setInterval(async () => {
        if (newWindow.closed) {
          setLoader({
            isLoading: false,
          });
          clearInterval(newWinInterval);
          // re-fetch tax info
          const customer = { ...customerInfo };
          const taxInfo = await APIRouter('TAX', 'getTaxExemptInfo', [[{ customerId: customer?.id, state: storeState, customerType: customer?.customerType }]], setLoader);
          const expirationDate = moment().endOf('day').add(1, 'day').format('YYYY-MM-DD');
          if (taxInfo?.taxCertificateNumber) {
            if (
              (taxInfo?.expirationDate &&
                moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(
                  expirationDate
                )) ||
              taxInfo.status === 'EXPIRED'
            ) {
              taxInfo.status = constants.STATUS_EXPIRED;
            }
            customer.taxExempt = taxInfo;
          }
          if (checkIfObjectHasKey(taxInfo, constants.INVALID_STATE_RES)) {
            customer.taxExempt = { status: constants.STATUS_STATE_MISMATCH };
          }
          setCustomerInfo(customer);
        }
      }, 1000);
    };

    // If user clicks continue, trigger pin pad signature
    const callBack = async () => {
      try {
        setLoader({
          isLoading: true,
          loadingText: constants.LOADER_PIN_PAD_WAIT,
        });

        const signatureImage = await API.GK.getCustomerSignature();

        setLoader({ isLoading: false });

        if (!signatureImage.signatureBypassed) {
          const data = [
            customerInfo.id,
            storeState,
            constants.STANDARD_CUSTOMER_TYPE,
            signatureImage?.signatureData,
            signatureImage?.signatureFormat,
          ];
          await APIRouter(
            'TAX',
            'signCertificateRequest',
            data,
            setLoader,
            false
          );
          await newTaxWindow();
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (
      constants.TAX_SIGNATURE_EXLCLUSION_STATES.includes(storeState) ||
      !API.GK.hasPOS()
    ) {
      await newTaxWindow();
    } else {
      // pop a confirmation dialog (ignore pop up and go straight to Avalara if New Mexico and Florida)
      setModalDetails({
        type: constants.MODAL_TYPE_TAX_CONFIRMATION_MODAL,
        title: constants.TAX_CONFIRMATION_MODAL_TITLE,
        subtitle: constants.TAX_CONFIRMATION_MODAL_SUBTITLE,
        cancelButton: constants.TAX_CONFIRMATION_MODAL_CANCEL_BUTTON,
        confirmButton: constants.TAX_CONFIRMATION_MODAL_CONFIRM_BUTTON,
        callBack,
      });
      setIsModalOn(true);
    }
  };
  return (
    <>
      {customerInfo.id &&
        (!isAllVehiclesView ? (
          <>
            <SplitBlock>
              <CustomerInformation
                isInspection={isInspection}
                moduleName={moduleName}
                isSinglePoint={isSinglePoint}
                isBusiness={isBusiness}
                customerInfo={{ ...customerInfo }}
                fieldErrors={fieldErrors}
                infoFields={infoFields}
                primaryFields={primaryFields}
                infoExpanded={infoExpanded}
                handleInput={handleInput}
                handleContactInput={handleContactInput}
                updateDropdown={updateDropdown}
                isSRPMIC={isSRPMIC}
                isAAA={isAAA}
                srpmicOpen={srpmicOpen}
                renderSRPMIC={renderSRPMIC}
                isCustomerEdit={!isBusiness && editInformation}
                clearSuffix={!isBusiness && editInformation ? closeSuffix : null}
                titleButtonType={
                  isBusiness || editInformation
                    ? null
                    : constants.BUTTON_TYPE_EDIT
                }
                titleButtonName={
                  isBusiness
                    ? !editInformation
                      ? constants.BUTTON_VIEW_CUSTOMER
                      : null
                    : !editInformation
                      ? constants.BUTTON_EDIT_CUSTOMER
                      : isInspection && moduleName !== constants.MODULE_EDIT_CUSTOMER
                        ? constants.BUTTON_VIEW_CUSTOMER
                        : null
                }
                onButtonClick={editCustomer}
                onButtonClick2={backToCustomerSummary}
                isVTVAircheck={isInspection && !infoExpanded}
                confirmClick={updateContactClick}
                requiredMet={contactRequiredMet}
              />
              <VerticalBar />
              <TaxSection
                customerInfo={customerInfo}
                title={constants.TITLE_TAX_EXEMPTION}
                titleButtonType={constants.BUTTON_TYPE_CREATE}
                titleButtonName={constants.BUTTON_TAX_EXEMPTION}
                onButtonClick={() => openTaxWindow()}
                isBlocked={isBlocked}
                isVTVAircheck={isInspection && !infoExpanded}
                isBusiness={isBusiness}
              />
            </SplitBlock>
            {isInspection && !editInformation && (
              <ViewMoreButtonWrapper>
                <ViewMoreButton
                  data-testid={
                    infoExpanded ? 'view_less_button' : 'view_more_button'
                  }
                  onClick={setExpandedInfo}
                >
                  {' '}
                  {expanded()}
                  {infoExpanded
                    ? 'View less customer & tax information'
                    : 'View more customer & tax information'}
                </ViewMoreButton>
              </ViewMoreButtonWrapper>
            )}
            {!editInformation && isInspection && (
              <>
                <DetailSection
                  title={
                    inspectionType === constants.AIRCHECK_REASON
                      ? constants.TITLE_AIRCHECK_DETAILS
                      : constants.TITLE_VTV_DETAILS
                  }
                  isInspection={isInspection}
                  backToCustomerSummary={backToCustomerSummary}
                  titleButtonsArr={vtvAircheckTitleButtons}
                  isVTVAircheck={isInspection}
                >
                  {selectedVehicle &&
                    inspectionFields &&
                    isInspection && (
                      <VehicleDetails
                        isHidden={isBlocked}
                        isVTVAircheck={inspectionFields}
                        selectedVehicle={selectedVehicle}
                        setSelectedVehicle={setSelectedVehicle}
                        editVehicle={isBlocked ? null : editVehicle}
                        inspectionFields={inspectionFields && inspectionFields}
                        setInspectionFields={
                          inspectionFields && setInspectionFields
                        }
                        initialInspection={
                          initialInspection && initialInspection
                        }
                      />
                  )}
                </DetailSection>
              </>
            )}
            {!editInformation && !isInspection && (
              <DetailSection
                isInspection={isInspection}
                isBlocked={isBlocked}
                title={constants.TITLE_VEHICLES}
                titleButtonsArr={titleButtonsArr}
                vehicles
              >
                {selectedVehicle &&
                  isVehicleActive(selectedVehicle?.vehicleDetails) ? (
                    <VehicleDetails
                      isBlocked={isBlocked}
                      selectedVehicle={selectedVehicle}
                      editVehicle={editVehicle}
                  />
                  ) : (
                    <VehicleWrapper justifyContent="flex-start">
                      {vehicles?.filter((v) => isVehicleActive(v))?.length > 0 ? (
                        vehicles.filter((v) => isVehicleActive(v)).map((c, index) => {
                          // limits results to 3
                          if (index > 2) return;
                          return (
                            <Vehicle
                              key={index}
                              isDetailView={false}
                              vehicle={c}
                              onSelect={
                                !isDetailView
                                  ? (e, c) => viewVehicleDetails(e, c)
                                  : () => {}
                              }
                              editVehicle={editVehicle}
                            />
                          );
                        })
                      ) : (
                        <NoVehiclesLabel>
                          This account has no vehicles. Add a New Vehicle to get
                          started.
                        </NoVehiclesLabel>
                      )}
                    </VehicleWrapper>
                  )}
                {showViewAllVehiclesLink && (
                  <ViewMoreButtonWrapper>
                    <ViewMoreButton
                      name="View All"
                      onClick={async () => {

                        const [customerVehicles, nonActiveVehicles] = await getCustomerVehicles(
                          customerInfo.id,
                          null,
                          deactivateOneTimeUseVehicles,
                          setLoader
                        );

                        setVehicles(customerVehicles);
                        setDeactivatedVehicles(nonActiveVehicles);
                        setIsAllVehiclesView(true);
                        setModuleName(constants.MODULE_CUSTOMER_VEHICLES);
                      }}
                    >
                      View All Vehicles{' '}
                    </ViewMoreButton>
                  </ViewMoreButtonWrapper>
                )}
              </DetailSection>
            )}
            {editInformation && isBusiness && (
              <SplitBlock gap="50px">
                <ShippingAddress
                  vehicles
                  title={constants.TITLE_SHIPPING_ADDRESS}
                >
                  <CustomerDetail
                    infoName={`${upperCaseFirstLetter(
                      customerInfo?.address1 || constants.EMPTY_STRING
                    )}
            ${
              customerInfo?.address2
                ? `, ${upperCaseFirstLetter(customerInfo.address2)}`
                : constants.EMPTY_STRING
            }`}
                  />
                  <CustomerDetail
                    infoName={`${upperCaseFirstLetter(
                      customerInfo?.city || constants.EMPTY_STRING
                    )}
            , ${upperCaseFirstLetter(
                      customerInfo?.state || constants.EMPTY_STRING
                    )} ${customerInfo?.zip || constants.EMPTY_STRING}`}
                  />
                </ShippingAddress>
                <EmailAddresses
                  vehicles
                  title={constants.TITLE_EMAIL_ADDRESSES}
                >
                  {isSinglePoint && (
                  <EmailWrapper>
                    <CustomerDetail
                      width="275px"
                      isBold
                      infoName={
                        isSinglePoint?.contactItem.title || constants.NOT_APPLICABLE
                      }
                    />
                    <CustomerDetail
                      width="150px"
                      infoName={`${upperCaseFirstLetter(
                        isSinglePoint?.contactItem.firstName
                      )} ${
                        isSinglePoint?.contactItem.middleName
                          ? `${upperCaseFirstLetter(
                            isSinglePoint?.contactItem.middleName
                          )} `
                          : ''
                      }${upperCaseFirstLetter(isSinglePoint?.contactItem.lastName)}`}
                    />
                    <CustomerDetail
                      infoName={
                        isSinglePoint?.contactItem.email
                          ? isSinglePoint?.contactItem.email
                          : constants.NOT_APPLICABLE
                      }
                    />
                  </EmailWrapper>
                  )}
                  {emailInvoiceRequired?.slice(0, 2).map((e) => (
                    <EmailWrapper>
                      <CustomerDetail
                        width="275px"
                        isBold
                        infoName={
                          e.contactItem.title || constants.NOT_APPLICABLE
                        }
                      />
                      <CustomerDetail
                        width="150px"
                        infoName={`${upperCaseFirstLetter(
                          e.contactItem.firstName
                        )} ${
                          e.contactItem.middleName
                            ? `${upperCaseFirstLetter(
                              e.contactItem.middleName
                            )} `
                            : ''
                        }${upperCaseFirstLetter(e.contactItem.lastName)}`}
                      />
                      <CustomerDetail
                        infoName={
                          e.contactItem.email
                            ? e.contactItem.email
                            : constants.NOT_APPLICABLE
                        }
                      />
                    </EmailWrapper>
                  ))}
                  {businessContacts(customerInfo)}
                </EmailAddresses>
              </SplitBlock>
            )}
            {!editInformation && !isInspection && (
              <SelectVTVAirCheck
                onClickSelectVTVAircheck={goToResultScreen}
                isBlocked={isBlocked}
                manual
              />
            )}
            {editInformation && !isBusiness && (
              <SplitBlock gap="40px">
                <CustomerEdit
                  editButtonClick={editPrimary}
                  formName={constants.CUSTOMER_EDIT_PRIMARY_FORM}
                  formType={primaryEdit && constants.CUSTOMER_EDIT_PRIMARY_FORM}
                  fields={primaryFields}
                  fieldErrors={fieldErrors}
                  handleInput={handleContactInput}
                  updateDropdown={updateDropdown}
                  cancelClick={cancelContactEdit}
                  confirmClick={updateContactClick}
                  requiredMet={contactRequiredMet}
                  checked={!checked}
                  setChecked={handleContactInput}
                  ref={bottomRef}
                  clearState={closeState}
                  clearSuffix={closeSuffix}
                  isPrimaryAlternateEdit={primaryEdit}
                  contactInfoErrorClicked={contactInfoErrorClicked}
                />
                <CustomerEdit
                  editButtonClick={editAlternate}
                  clearAlternateEdit={clearAlternateEdit}
                  formName={constants.CUSTOMER_EDIT_ALTERNATE_FORM}
                  formType={
                    alternateEdit && constants.CUSTOMER_EDIT_ALTERNATE_FORM
                  }
                  fields={alternateFields}
                  fieldErrors={fieldErrors}
                  handleInput={handleContactInput}
                  updateDropdown={updateDropdown}
                  cancelClick={cancelContactEdit}
                  confirmClick={updateContactClick}
                  requiredMet={contactRequiredMet}
                  clearState={closeState}
                  clearSuffix={closeSuffix}
                  checked={checked}
                  ref={bottomRef}
                  setChecked={handleContactInput}
                  isPrimaryAlternateEdit={alternateEdit}
                />
              </SplitBlock>
            )}
          </>
        ) : (
          <CustomerVehicles
            titleButtonsArr={titleButtonsArr}
            isBlocked={isBlocked}
            selectVehicle={viewVehicleDetails}
            editVehicle={editVehicle}
            selectedVehicle={selectedVehicle}
            showDeactivatedVehicle={showDeactivatedVehicles}
            applyCustomerVehicleError={applyCustomerVehicleError}
          />
        ))}
    </>
  );
};

export default CustomerSummary;

