import {
  useContext, useEffect, useRef, useState
} from 'react';
import styled from '../../../../helpers/esm-styled-components';
import {
  colorOptions,
  countryOptions, getStateOrProvincePlaceholder,
} from '../../../../helper/baseDataHelper';
import { APIContext } from '../../../../Data/API';
import Dropdown from '../../../Dropdown/Dropdown';
import Input from '../../../Input/Input';
import { theme } from '../../../../helpers/GlobalStyles';
import { handleInput } from '../../../Input/InputHelper';
import * as constants from '../../../../constants/Constants';
import TextArea from '../../../TextArea/TextArea';
import Message from '../../../Error/Message';
import { APIRouter } from '../../../../Data/APIRouter';
import { simulateAction } from '../../../../helper/simulateActions';
import {
  FactoryTireSize, FactoryTireSizeLabel, FactoryTireSizeWrapper, OEBadge, OEBadgeWrapper, SmallVerticalBar, WheelSize, WheelSizeLabel
} from '../../../../components/Details/Vehicle/VehicleDetails';
import {
  Badge,
  BadgeWrapper,
  LightHorizontalDivider, OneTimeUseQuestionLabel, SmVerticalBar, TireInfoContainer, TireSize, TireSizeLabel, TireSizeWrapper, ToggleLabelContainer, ToggleWrapper, VehicleBaseInfoContainer, VehicleBaseInfoModel, VehicleBaseInfoTrim, VehicleBaseInfoYearMake, VehicleQuestionContainer, WSize, WSizeLabel, WinterUseQuestionLabel
} from './VehicleWizard';
import AssemblyDropdown from '../../../Dropdown/AssemblyDropdown';
import Toggle from '../../../Toggle/Toggle';
import { isNullEmptyUndefined } from '../../../../helper/validationHelper';

const AdditionalInfoContainer = styled.form`
  display: grid;
  grid-gap: ${({ errorMessage }) => (errorMessage ? '50px 15px' : '15px')};
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'color color vin'
    'plate state country'
    'notes notes notes';
`;

const VehicleColorContainer = styled.div`
  grid-area: color;
  `;

const VehicleColorDropDown = styled(Dropdown)`
  width: 472px;
`;

const SearchInput = styled(Input)`
  font-size: 12px;
  width: auto;
`;

const VINField = styled(SearchInput)`
  width: 220px;
`;

const LicensePlate = styled(SearchInput)`
  grid-area: plate;
  width: 225px;
`;

const LicenseState = styled(Dropdown)`
  grid-area: state;
  width: 230px;
`;

const LicenseCountry = styled(Dropdown)`
  grid-area: country;
  width: 220px;
`;

const Notes = styled(TextArea)`
  grid-area: notes;
`;

export const CharCountLabel = styled.p`
  text-align: right;
  margin-top: 3px;
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.normal};
  color: ${theme.colors.titleBlack};
`;

const VINLabel = styled.p`
  font-size: ${theme.fontSize.large};
  font-family: ${theme.fontFamily.bold};
  color: ${theme.colors.darkGray};
  &:before {
    content: "VIN: ";
    color: ${theme.colors.lightGray};
    font-family: ${theme.fontFamily.normal};
  }
`;

const VINContainer = styled.div`
  position: relative;
  grid-area: vin;
`;

const AdditionalVehicleInformation = ({
  standardVehicleFields, setStandardVehicleFields, errorMessage, arChargeFields, fitmentImage, isEdit
}) => {
  // context
  const { getStateOptions, getStateOrProvince } = useContext(APIContext);
  useEffect(() => {
    if (!isEdit) {
    // Focus on the first input field after component has mounted
      const elementWithClass = document.querySelector('[name="color"]');
      if (elementWithClass) {
        elementWithClass.focus();
      }
    }
  }, []);
  // states
  const [charCount, setChartCount] = useState(0);

  // local variables
  const defaultCountry = countryOptions[0];
  /**
   * Dropdown selection handler for Color
   * @param {Object} value
   */

  const updateColor = (value) => {
    // check if we have autosync data
    if (standardVehicleFields.autoSyncData?.autoSync) {
      setStandardVehicleFields({
        ...standardVehicleFields,
        imageUrl: value.image ? value.image : fitmentImage,
        autoSyncData: {
          ...standardVehicleFields.autoSyncData,
          autoSyncColor: { value: value.value, label: value.value },
        },
        color: { value: value.shortName, label: value.shortName }
      });
    } else {
      const item = colorOptions.filter((option) => option.label === value.label);
      if (item) {
        setStandardVehicleFields({
          ...standardVehicleFields,
          color: item[0],
        });
      }
    }
  };

  /**
   * Dropdown selection handler for Country
   * @param {Object} value
   */
  const updateCountry = (value) => {
    const item = countryOptions.filter((option) => option.label === value.label);
    if (item) {
      if (standardVehicleFields.licenseCountry?.value !== item[0]?.value) {
        setStandardVehicleFields({ ...standardVehicleFields, licenseCountry: item[0], licenseState: constants.EMPTY_STRING });
      } else {
        setStandardVehicleFields({ ...standardVehicleFields, licenseCountry: item[0] });
      }
    }
  };

  /**
   * Dropdown selection handler for State
   * @param {Object} value
   */
  const updateState = (value) => {
    const item = getStateOrProvince(standardVehicleFields.licenseCountry?.value, value);
    if (item) {
      setStandardVehicleFields({ ...standardVehicleFields, licenseState: item[0] });
    }
  };

  // Properties for the Country Dropdown
  const countryDropdownProps = {
    fieldName: 'licenseCountry',
    placeholder: 'Country',
    options: countryOptions,
    onChange: updateCountry,
    selectedOption: standardVehicleFields.licenseCountry || defaultCountry,
    value: standardVehicleFields.licenseCountry?.label,
    required: false,
    positionStyle: 'absolute',
    dropdownHeight: 135,
    disabled: standardVehicleFields.isCarryOut,
  };

  const closeState = () => {
    setStandardVehicleFields({ ...standardVehicleFields, licenseState: constants.EMPTY_STRING });

  };

  // Properties for the State Dropdown
  const stateDropdownProps = {
    fieldName: 'licenseState',
    placeholder: getStateOrProvincePlaceholder(standardVehicleFields.licenseCountry?.value || defaultCountry.value),
    options: getStateOptions(standardVehicleFields.licenseCountry?.value || defaultCountry.value),
    onChange: updateState,
    selectedOption: standardVehicleFields.isCarryOut ? { value: 'AZ', label: 'Arizona' } : standardVehicleFields.licenseState ? getStateOrProvince(standardVehicleFields.licenseCountry?.value, standardVehicleFields.licenseState)[0] : constants.EMPTY_STRING,
    value: standardVehicleFields.isCarryOut ? { value: 'AZ', label: 'Arizona' } : standardVehicleFields.licenseState ? getStateOrProvince(standardVehicleFields.licenseCountry?.value, standardVehicleFields.licenseState)[0] : constants.EMPTY_STRING,
    required: arChargeFields?.map(f => f.field).includes('LIC PLATE STATE'),
    positionStyle: 'absolute',
    dropdownHeight: 135,
    disabled: standardVehicleFields.isCarryOut,
    onClickClear: closeState
  };

  const colorDropdownProps = {
    fieldName: 'color',
    placeholder: 'Vehicle Color',
    options: standardVehicleFields.autoSyncData?.autoSync ? standardVehicleFields.autoSyncData?.autoSync.map(c => ({
      value: c.Name, label: c.Name, image: c.Image, shortName: c.ShortName
    })) : colorOptions,
    onChange: updateColor,
    selectedOption: standardVehicleFields.autoSyncData?.autoSync
      ? typeof standardVehicleFields.autoSyncData?.autoSyncColor === 'string' ? { value: standardVehicleFields.autoSyncData?.autoSyncColor, label: standardVehicleFields.autoSyncData?.autoSyncColor } : standardVehicleFields.autoSyncData?.autoSyncColor
      : typeof standardVehicleFields.color === 'string' ? { value: standardVehicleFields.color, label: standardVehicleFields.color } : standardVehicleFields.color,
    value: standardVehicleFields.autoSyncData?.autoSync ? standardVehicleFields.autoSyncData?.autoSyncColor : standardVehicleFields.color,
    required: false,
    dropdownHeight: '200'
  };

  /**
   * Input handler for the notes text area
   * @param {String} value
   */
  const handleNotesInput = (value) => {
    setChartCount(value.length);
    setStandardVehicleFields({
      ...standardVehicleFields,
      notes: value,
    });
  };
  return (
    <>
      <AdditionalInfoContainer errorMessage={errorMessage} >
        <VehicleColorContainer>
          <VehicleColorDropDown {...colorDropdownProps} />
        </VehicleColorContainer>
        <VINContainer>
          { standardVehicleFields.isManual || isNullEmptyUndefined(standardVehicleFields?.vin)
            ? (
              <>
                <VINField
                  type={String}
                  required={arChargeFields?.map(f => f.field).includes('VIN #')}
                  fieldName="vin"
                  inputValue={standardVehicleFields.isCarryOut ? 'CarryOut' : standardVehicleFields?.vin}
                  label="VIN"
                  errorMessages={[]}
                  warningMessages={[]}
                  inputHandler={(value, fieldName) => handleInput(standardVehicleFields, setStandardVehicleFields, value, fieldName)}
                  isDisabled={standardVehicleFields.isCarryOut}
                  maxLength={17}
                  capsLock
                />
                <Message errorMessages={errorMessage?.message} type={errorMessage?.type} />
              </>
            )
            : <VINLabel>{standardVehicleFields.vin}</VINLabel>}
        </VINContainer>
        <LicensePlate
          required={arChargeFields?.map(f => f.field).includes('LICENSE PLATE #')}
          type={String}
          fieldName="licensePlate"
          inputValue={standardVehicleFields.isCarryOut ? 'CarryOut' : standardVehicleFields.licensePlate || standardVehicleFields.licensePlateNumber}
          label="License Plate Number"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={(value, fieldName) => handleInput(standardVehicleFields, setStandardVehicleFields, value, fieldName)}
          isDisabled={standardVehicleFields.isCarryOut}
          maxLength={10}
          capsLock
        />
        <LicenseState {...stateDropdownProps} />
        <LicenseCountry {...countryDropdownProps} />
        <Notes
          type={String}
          required={false}
          fieldName="notes"
          inputValue={standardVehicleFields.notes}
          label="Notes"
          errorMessages={[]}
          warningMessages={[]}
          inputHandler={handleNotesInput}
          isDisabled={false}
          maxLength={255}
          height="80px"
        />

      </AdditionalInfoContainer>
      <CharCountLabel>
        {charCount}/{255}
      </CharCountLabel>
    </>
  );
};

export default AdditionalVehicleInformation;
