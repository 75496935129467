import React, { useContext } from 'react';
import styled from '../../../helpers/esm-styled-components';
import * as constants from '../../../constants/Constants';
import { CVMContext } from '../../CVM/CVM';
import DetailSection from '../DetailSection';
import SelectButton from '../../../UIComponents/Button/SelectButton';
import { defaultInspectionQuery } from '../../../helper/baseDataHelper';

const DetailGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 335px);
  grid-template-rows: auto;
  grid-template-areas: 'vtv aircheck manualvtv .';
`;
const SelectVTV = styled(SelectButton)`
  box-sizing: border-box;
  grid-area: vtv;
  ${({ isHidden }) => isHidden && 'color: grey; cursor: not-allowed;'}
`;
const SelectAirCheck = styled(SelectVTV)`
  grid-area: aircheck;
`;
const ManualVTV = styled(SelectVTV)`
   grid-area: manualvtv;
`;


const SelectVTVAirCheck = (props) => {
  const {
    setSearchOrigin,
    setSearchQuery,
    setIsManualVtv,
    selectedVehicle,
  } = useContext(CVMContext);
  const { manual, isBlocked, onClickSelectVTVAircheck } = props;
  const selectManualVTV =  () => {
    if (!selectedVehicle || isBlocked){
      return;
    }
    setIsManualVtv(true);
  };
  const selectVTV = () => {
    setSearchQuery(defaultInspectionQuery());
    setSearchOrigin(constants.SEARCH_ORIGIN_VTV);
  };

  const selectAirCheck = () => {
    setSearchQuery(defaultInspectionQuery());
    setSearchOrigin(constants.SEARCH_ORIGIN_AIRCHECK);
  };

  return (
    <DetailSection title={constants.TITLE_SELECT_VTV_AIRCHECK} manual={manual}>
      <DetailGrid>
        <SelectVTV
          isHidden={isBlocked}
          buttonType={constants.DEFAULT}
          buttonName={constants.TITLE_SELECT_BY_VTV}
          onButtonClick={!onClickSelectVTVAircheck ? !isBlocked && selectVTV : () => !isBlocked && onClickSelectVTVAircheck(true)}
          isDisabled={false}
          tabIndex={1 || null}
        />
        <SelectAirCheck
          isHidden={isBlocked}
          buttonType={constants.DEFAULT}
          buttonName={constants.TITLE_SELECT_BY_AIRCHECK}
          onButtonClick={!onClickSelectVTVAircheck ? !isBlocked && selectAirCheck : () => !isBlocked && onClickSelectVTVAircheck(false)}
          isDisabled={false}
          tabIndex={2 || null}
        />
        {selectedVehicle && manual && (
          <ManualVTV
            isHidden={isBlocked}
            buttonType={constants.DEFAULT}
            buttonName={constants.TITLE_SELECT_BY_MANUAL_VTV}
            onButtonClick={selectManualVTV}
          />
        )}
      </DetailGrid>
    </DetailSection>
  );
};

export default SelectVTVAirCheck;
